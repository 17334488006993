import moment from "moment-timezone";
import { Tooltip } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, merge, set, concat, isEmpty } from "lodash";
import { checkIfAmazon } from "./common";
import ReactTable from "../../../components/Common/Table";
import { getRepricerListings } from "../../../store/actions";
import { toPascalCase, getProductURL } from "../../../utils/common";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import { DATE_FORMATS, REPRICER_LISTING_TYPES } from "../../../constants";

const { DATE_TIME } = DATE_FORMATS;

const ErrorAndProgressTable = ({ state }) => {
  const dispatch = useDispatch();
  const [allStoresObj, setAllStoresObj] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState({});
  const isErroredTable = state?.listingsPage.value === REPRICER_LISTING_TYPES.errored;

  const itemsPerPage = state?.filters?.per_page;
  const currentPage = state?.filters?.page - 1;
  const search = state?.filters?.search;
  const searchOn = state?.filters?.search_on;

  const mpAccountId = get(state?.storeFront, "identifier");
  const selectedPlatform = get(state?.storeFront, "marketplace");
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);

  const { repricerListings } = useSelector((state) => state.Repricer);
  const results = get(repricerListings, "results", []);

  const isMarketplaceAmazon = checkIfAmazon(selectedPlatform);
  const headers = concat(
    ["SKU", isMarketplaceAmazon ? "ASIN" : "UPC"],
    isErroredTable ? ["Error Type"] : [],
    ["Created At"],
  );

  const sanitizeId = (id) => id.replace(/[^a-zA-Z0-9-_]/g, "_");

  const getUniqueKey = (item) => {
    const uniquePart = `${item.sku}-${item.updated_at}`;
    return sanitizeId(uniquePart);
  };

  const toggleTooltip = (itemId) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  useEffect(() => {
    if (Array.isArray(accounts?.data) && !Object.keys(allStoresObj).length) {
      setAllStoresObj(accounts?.data.reduce((obj, acc) => merge(obj, { [acc?.id]: acc }), {}));
    }
  }, [accounts]);

  useEffect(() => {
    const limit = itemsPerPage;
    const offset = currentPage * itemsPerPage;
    const payload = { selectedPlatform, mpAccountId, limit, offset, search, searchOn };
    set(payload, "status", "IN_PROGRESS");
    if (isErroredTable) {
      set(payload, "status", "ERRORED");
    }
    dispatch(getRepricerListings(payload));
  }, [state?.storeFront, currentPage, itemsPerPage, search, searchOn, isErroredTable]);

  return (
    <React.Fragment>
      <ReactTable
        tableHeader={
          <>
            <th />
            {headers.map((header, index) => (
              <th key={`${isErroredTable ? "table-header-errored" : "table-header-progress"} ${index}`}>{header}</th>
            ))}
          </>
        }
        tableBody={
          !isEmpty(results) ? (
            results.map((item, key) => {
              const uniqueKey = getUniqueKey(item);
              return (
                <tr
                  key={isErroredTable ? "_listings_pending_" : "_listings_in_progress_" + key}
                  className={"mt-3 mb-3"}
                >
                  <td />
                  <td>
                    {item?.sku}
                    <CopyToClipBoard text={item?.sku} />
                  </td>
                  <td>
                    <a
                      href={getProductURL(item, state?.storeFront.marketplace, allStoresObj[item.partner_id])}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary mb-1"
                    >
                      {isMarketplaceAmazon ? item.asin : item.upc}
                    </a>
                    <CopyToClipBoard text={isMarketplaceAmazon ? item.asin : item.upc} />
                  </td>
                  {isErroredTable ? (
                    <td>
                      <div className="d-flex align-items-center">
                        <i
                          id={`__errored_listing_log__${uniqueKey}`}
                          className="bx bx-info-circle mr-1 danger cursor-pointer"
                        />
                        {item?.error_description ? (
                          <Tooltip
                            placement={"right"}
                            isOpen={tooltipOpen[uniqueKey] || false}
                            target={`__errored_listing_log__${uniqueKey}`}
                            toggle={() => toggleTooltip(`${uniqueKey}`)}
                            style={{ padding: "5px" }}
                          >
                            {item?.error_description}
                          </Tooltip>
                        ) : null}
                        {toPascalCase(item.error_type)}
                      </div>
                    </td>
                  ) : null}
                  <td>{(item?.updated_at && moment(item?.updated_at).format(DATE_TIME)) || ""}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={headers.length + 1} className="text-center">
                <h2 className="m-2 p-2">No Records Found</h2>
              </td>
            </tr>
          )
        }
        key={isErroredTable ? "errored" : "inprogress"}
      />
    </React.Fragment>
  );
};
export default ErrorAndProgressTable;
