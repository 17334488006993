import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

// components
import { Card, Alert, Row, Label, Col, CardHeader } from "reactstrap";
import CopyToClipBoard from "../../../components/Common/CopyToClipBoard";
import { ConfirmDialogBtn, ConfirmDialogIcon } from "../../../components/Common/ConfirmDialog";
import PillBtn from "../../../components/Common/PillBtn";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import SweetAlert from "react-bootstrap-sweetalert";
import stampsSvg from "../../../assets/svg/brands/StampIconSmall.webp";
import AddAgencyModal from "../components/AddAgencyModal";
import EditAgencyProfitModal from "../components/EditAgencyProfitModal";
import { getUserInfo, RenderIf } from "../../../utils/common";
import {
  MARKET_PLACES,
  USER_ROLES,
  STAMPS_AUTH_URL,
  SHOW_IS_LIQUIDATED,
} from "../../../constants/index";
import { uniqBy, keys, some } from "lodash";
import BotTriggersModal from "../components/BotTriggersModal";
// actions
import {
  updateAccountSettings,
  updateDropShipping,
  setPreloader,
  fetchPlatforms,
  fetchAOBotStatus,
  updatePlatform,
  setAddAgencyModal,
  unlinkAgency,
  checkForAOStatusUpdate,
  fetchMarketplaceAccounts,
  setPaymentReportModal,
  setAgencyProfitModal,
} from "../../../store/actions";

import { addStampsCreds, apiError, deleteStampsCreds, enableStampsCreds } from "../../../store/settings/actions";
import { apiError as mpApiError } from "../../../store/marketPlaceAccounts/actions";
import getBrandImageSrc from "../../../utils/brandImages";
import CheckBox from "../../../components/Common/CheckBox";
import DisableEmailForwarder from "../../../components/Common/DisableEmailForwarder";

const AccountSettings = (props) => {
  const dispatch = useDispatch();
  const {
    account,
    agencies,
    success,
    error,
    loading,
    isAddAgencyModalOpen,
    isTriggerModal,
    agencyProfitModalIsOpen,
    tenantsData,
    fetchStampsCredsData,
    stampsSuccess,
  } = useSelector((state) => state.Settings);
  const { accounts: marketplaceAccounts } = useSelector((state) => state.MarketPlaceAccount);
  const isLiquidatedAcess = some(SHOW_IS_LIQUIDATED, email => email.toLowerCase() === getUserInfo()?.user_email?.toLowerCase());

  const { platforms, loading: platformLoading, success: platformSuccess } = useSelector((state) => state.Platform);
  const [isOpen, setIsOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [isUSPSEnableOpen, setIsUSPSEnableOpen] = useState(false);
  const [agencyMarketPlaces, setAgencyMarketPlaces] = useState();
  const [agency, setAgency] = useState();
  const [updateData, setData] = useState();
  const [popupConfig, setPopupConfig] = useState({});
  const [disableEmailForwarder, setDisableEmailForwarder] = useState(false);
  const uniqueMarketPlaces = useRef([]);

  const [isEnableDropShipping, setIsEnableDropShipping] = useState(false);

  // Update state when account data is loaded
  useEffect(() => {
    if (account?.data?.enable_dropshipping !== undefined) {
      setIsEnableDropShipping(account.data.enable_dropshipping);
    }
  }, [account]);

  // Function to handle checkbox toggle and update database
  const handleToggle = async () => {
    const newValue = !isEnableDropShipping;
    setIsEnableDropShipping(newValue);
    setData({ enable_dropshipping: !account?.data?.enable_dropshipping });

    dispatch(updateAccountSettings({enable_dropshipping: newValue}, account.data.id));
  };

  function setLoading (bool) {
    dispatch(setPreloader(bool));
  }
  const restrictedToggle = (marketplace) =>
    !!platforms?.data?.find((x) => x.name === marketplace)?.retire_restricted_brands;
  function openStampsLogin () {
    window.open(STAMPS_AUTH_URL(), "_blank");
  }
  function updateSettings (data) {
    dispatch(updateAccountSettings(data));
  }

  function updateIsLiquidated () {
    setData({ is_liquidated: !account?.data?.is_liquidated });
    setPopupConfig({
      ...popupConfig,
      field: "is_liquidated",
      notice: "Do you want to Liquidate account",
    });
    setIsOpen(true);
  }

  function updateStatus () {
    account?.data?.status === "active" && setData({ status: "in_active" });
    account?.data?.status === "in_active" && setData({ status: "active" });
    setIsOpen(true);
  }

  function updateTaxToRepricer () {
    setData({ est_shipping_to_repricer: !account?.data?.est_shipping_to_repricer });
    setPopupConfig({
      ...popupConfig,
      field: "est_shipping_to_repricer",
      notice: "Do you want to send estimated fee to repricer",
    });
    setIsOpen(true);
  }

  function isChild () {
    return account?.data.id === account?.data.agency_id;
  }

  function isParent () {
    return account?.data.agency_id === getUserInfo().account_id;
  }

  function getAgencyData () {
    const loggedInAccount = agencies?.data?.find((x) => x.id === getUserInfo().account_id);
    if (
      keys(loggedInAccount).length &&
      getUserInfo().role === USER_ROLES.admin &&
      loggedInAccount?.id !== loggedInAccount?.agency_id
    )
      return [loggedInAccount];
    // If Parent Account - Give Rest of Accounts Other than Parent
    // Else Give Parent Account Only
    if (loggedInAccount?.agency_id === null) {
      return agencies?.data?.filter((x) => x.id !== getUserInfo().account_id);
    } else {
      return agencies?.data?.filter((x) => x.agency_id === null);
    }
  }

  const { code: stampsAuthCode } = queryString.parse(document.location.search);
  useEffect(() => {
    stampsAuthCode &&
      dispatch(
        addStampsCreds({
          auth_token: stampsAuthCode,
        }),
      ) &&
      window.history.replaceState(null, "", "/settings?account=true");
  }, []);

  function updateRestrictedBrand (marketplace) {
    const { retire_restricted_brands: status, id } = platforms?.data?.find((x) => x.name === marketplace);
    dispatch(updatePlatform({ data: { retire_restricted_brands: !status }, id }));
  }

  useEffect(() => {
    if (marketplaceAccounts?.data) {
      uniqueMarketPlaces.current = uniqBy(marketplaceAccounts?.data, (x) => x.marketplace).map((x) => x.marketplace);
      dispatch(fetchPlatforms({ sites: uniqueMarketPlaces.current }));
    }
  }, [marketplaceAccounts]);

  useEffect(() => {
    setLoading(loading || platformLoading);
  }, [loading, platformLoading]);

  useEffect(() => {
    account?.data?.ao_enabled === true && dispatch(fetchAOBotStatus());
  }, [account?.data?.ao_enabled]);

  const isWalmartStoreExists = marketplaceAccounts?.data.some((x) => x.marketplace === MARKET_PLACES.walmart);

  useEffect(() => {
    dispatch(apiError(""));
    dispatch(mpApiError(""));
  }, []);

  return (
    <React.Fragment>
      {/* Render Breadcrumb */}
      <DisableEmailForwarder isOpen={disableEmailForwarder} setIsOpen={setDisableEmailForwarder} />
      {isAddAgencyModalOpen && <AddAgencyModal />}
      {agencyProfitModalIsOpen && (
        <EditAgencyProfitModal
          isOpen={agencyProfitModalIsOpen}
          setIsOpen={props.setAgencyProfitModal}
          MarketPlaceAccount={{ accounts: agencyMarketPlaces }}
          agency={agency}
        />
      )}
      {isTriggerModal && <BotTriggersModal account={account} />}
      <SweetAlert
        title="Are you sure?"
        warning
        showCancel
        show={isUSPSEnableOpen}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={(_) => {
          dispatch(enableStampsCreds({ enabled: !fetchStampsCredsData?.enabled }));
          setIsUSPSEnableOpen(false);
        }}
        onCancel={() => setIsUSPSEnableOpen(false)}
      >
        {`Do you want to ${fetchStampsCredsData?.enabled ? "disable" : "enable"} stamps settings`}
      </SweetAlert>
      <SweetAlert
        title="Are you sure?"
        warning
        showCancel
        show={isOpen}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={(_) => {
          if (confirmationType) {
            updateRestrictedBrand(confirmationType);
            setIsOpen(false);
            setConfirmationType("");
          } else {
            if (updateData?.ao_enabled) dispatch(checkForAOStatusUpdate(updateData));
            else updateSettings(updateData);
            if (Object.keys(popupConfig).length) setPopupConfig({});
            setIsOpen(false);
          }
        }}
        onCancel={() => {
          setIsOpen(false);
          setConfirmationType("");
          if (Object.keys(popupConfig).length) setPopupConfig({});
        }}
      >
        {confirmationType
          ? `${
              !restrictedToggle(confirmationType) ? "Enable" : "Disable"
            } Retired/Delete Restricted Brand for ${confirmationType}`
          : popupConfig.notice || "To update your account setting!"}
      </SweetAlert>
      {(success || platformSuccess) && (
        <div className="auto-hide">
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {success || platformSuccess}
          </Alert>
        </div>
      )}
      <RenderIf isTrue={stampsSuccess}>
        <div className="auto-hide">
          <Alert color="success">
            <i className="bx bx-info-circle pr-2"></i>
            {stampsSuccess}
          </Alert>
        </div>
      </RenderIf>
      {error && (
        <div className="auto-hide">
          <Alert color="danger">
            <i className="bx bx-info-circle pr-2"></i>
            {error}
          </Alert>
        </div>
      )}
      <Card>
        <Row>
          <Col>
            {account?.data && (
              <>
                <div className="d-flex justify-content-between mb-4">
                  <div className="d-flex flex-column">
                    <div className="custom-control custom-switch custom-switch-lg mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="account_status"
                        checked={account?.data?.status === "active"}
                        onChange={() => null}
                      />
                      <label onClick={updateStatus} className="custom-control-label cursor-pointer">
                        Account Status
                        <small> (In case of inactive no order will import)</small>
                      </label>
                    </div>

                    <div>
                      {/* Enable Dropshipping Checkbox */}
                      <div className="custom-control custom-switch custom-switch-lg mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="enable_dropshipping"
                          checked={isEnableDropShipping}
                          onChange={handleToggle}
                        />
                        <label
                          className="custom-control-label cursor-pointer"
                          htmlFor="enable_dropshipping"
                        >
                          Enable Dropshipping
                        </label>
                      </div>

                      {/* Dependent Checkboxes (only visible if Enable Dropshipping is checked) */}
                      {isEnableDropShipping && (
                        <div className="ml-4">
                          {/* Two Step Feature Checkbox */}
                          <div className="custom-control custom-switch custom-switch-lg mb-2">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="two_step_feature"
                              checked={account?.data?.two_step_feature}
                              onChange={() => null}
                            />
                            <label
                              onClick={() => {
                                const { two_step_feature: twoStep } = account?.data;
                                setData({ two_step_feature: !twoStep });
                                setPopupConfig({
                                  ...popupConfig,
                                  field: "two_step_feature",
                                  notice: (
                                    <>
                                      <p>To {twoStep ? "Disable" : "Enable"} 2 Step feature</p>
                                      <p>
                                        <RenderIf isTrue={twoStep}>
                                          Warning: Disabling this will disable 2 Step on all stores, but not on listings
                                        </RenderIf>
                                      </p>
                                    </>
                                  ),
                                });
                                setIsOpen(true);
                              }}
                              id="_two_step_notice_"
                              className="custom-control-label cursor-pointer"
                            >
                              Two Step Feature
                            </label>
                          </div>

                          {/* Tooltip for Two Step Feature */}
                          {account?.data && !account?.data?.is_two_step_paid && (
                            <div className="ml-4">
                              <span id="two_step_notice" className="ml-2 text-muted">
                                ℹ️
                              </span>
                              <Tooltip
                                placement="top"
                                isOpen={isTooltipOpen}
                                target="two_step_notice"
                                toggle={() => setIsTooltipOpen(!isTooltipOpen)}
                              >
                                Some two-step items have payments pending
                              </Tooltip>
                            </div>
                          )}

                          {/* Estimated Shipping Fee to Repricer Checkbox */}
                          <div className="custom-control d-flex align-items-center custom-switch custom-switch-lg">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="est_shipping_to_repricer"
                              checked={account?.data?.est_shipping_to_repricer}
                              onChange={() => null}
                            />
                            <label
                              onClick={updateTaxToRepricer}
                              htmlFor="est_shipping_to_repricer"
                              className="custom-control-label cursor-pointer"
                            >
                              Estimated Shipping Fee to Repricer
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    
                    {/*<div className="custom-control custom-switch custom-switch-lg mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="account_status"
                        checked={account?.data?.two_step_feature}
                        onChange={() => null}
                      />
                      <label
                        onClick={() => {
                          const { two_step_feature: twoStep } = account?.data;
                          setData({ two_step_feature: !twoStep });
                          setPopupConfig({
                            ...popupConfig,
                            field: "two_step_feature",
                            notice: (
                              <>
                                <p>To {twoStep ? "Disable" : "Enable"} 2 Step feature</p>
                                <p>
                                  <RenderIf isTrue={twoStep}>
                                    Warning: Disabling this will disable 2 Step on all stores, but not on listings
                                  </RenderIf>
                                </p>
                              </>
                            ),
                          });
                          setIsOpen(true);
                        }}
                        id="_two_step_notice_"
                        className="custom-control-label cursor-pointer"
                      >
                        Two Step Feature
                      </label>
                    </div>
                    {account?.data && !account?.data?.is_two_step_paid && (
                      <CustomTooltip
                        placement="top"
                        content="Some two step items have payments pending"
                        target="_two_step_notice_"
                      />
                    )}
                    <div className="custom-control d-flex align-items-center custom-switch custom-switch-lg">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="est_shipping_to_repricer"
                        checked={account?.data?.est_shipping_to_repricer}
                        onChange={() => null}
                      />
                      <label onClick={updateTaxToRepricer} className="custom-control-label  cursor-pointer">
                        Estimated Shipping Fee to Repricer
                      </label>
                    </div> */}
                    
                    <RenderIf isTrue={isLiquidatedAcess}>
                      <div className="custom-control d-flex align-items-center custom-switch custom-switch-lg mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="est_shipping_to_repricer"
                          checked={account?.data?.is_liquidated}
                          onChange={() => null}
                        />
                        <label
                          onClick={updateIsLiquidated}
                          title="Turning this setting on will instruct warehouse to liquidate/discard/donate anything received in future against this tenant."
                          className="custom-control-label  cursor-pointer"
                        >
                          Is Liquidated
                        </label>
                      </div>
                    </RenderIf>
                  </div>
                  <div className="d-flex flex-column">
                    <RenderIf isTrue={uniqueMarketPlaces.current.includes(MARKET_PLACES.walmart)}>
                      <div className="custom-control d-flex align-items-center custom-switch custom-switch-lg">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="account_status"
                          checked={restrictedToggle(MARKET_PLACES.walmart)}
                          onChange={() => null}
                        />
                        <label
                          onClick={() => {
                            setConfirmationType(MARKET_PLACES.walmart);
                            setIsOpen(true);
                          }}
                          className="custom-control-label cursor-pointer"
                        >
                          Retired/Delete Restricted Brand
                        </label>
                        <img src={getBrandImageSrc(MARKET_PLACES.walmart)} alt="store logo" />
                      </div>
                    </RenderIf>
                    <RenderIf isTrue={uniqueMarketPlaces.current.includes(MARKET_PLACES.amazon)}>
                      <div className="custom-control d-flex align-items-center custom-switch custom-switch-lg">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="account_status"
                          checked={restrictedToggle(MARKET_PLACES.amazon)}
                          onChange={() => null}
                        />
                        <label
                          onClick={() => {
                            setConfirmationType(MARKET_PLACES.amazon);
                            setIsOpen(true);
                          }}
                          className="custom-control-label  cursor-pointer"
                        >
                          Retired/Delete Restricted Brand
                        </label>
                        <img src={getBrandImageSrc(MARKET_PLACES.amazon)} alt="store logo" />
                      </div>
                    </RenderIf>
                  </div>
                </div>
                <Label>Account Email</Label>
                <p>
                  {account?.data?.email}
                  <CopyToClipBoard text={account?.data?.email} />
                </p>
                <Label>
                  Forwarder Email{" "}
                  <span className="text-primary cursor-pointer" onClick={() => setDisableEmailForwarder(true)}>
                    {" "}
                    (How to disable email forwarding from email account)
                  </span>
                </Label>
                <p>
                  {account?.data?.forwarder_email}
                  <CopyToClipBoard text={account?.data?.forwarder_email} />
                </p>
                <hr></hr>

                {getUserInfo().account_id !== 1942 && (
                  <table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "50%" }}>Agency Email</th>
                        <th colSpan="3">Actions</th>
                        {((getUserInfo()?.role === "owner" && isChild()) || getUserInfo()?.role === "admin") && (
                          <th className="d-flex" style={{ justifyContent: "flex-end" }}>
                            <PillBtn
                              color="primary"
                              title="Invite to Agency"
                              name="Invite to Agency"
                              icon="bx bx-plus"
                              onClick={() => dispatch(setAddAgencyModal(true))}
                            />
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {getAgencyData()?.length
                        ? (
                            getAgencyData().map((agency, key) => (
                          <tr key={"_agency_" + key}>
                            <td>{tenantsData[agency?.agency_id]?.email || agency.email}</td>
                            <>
                              <td>
                                <ConfirmDialogBtn
                                  button={{
                                    color: "warning",
                                    title: "Unlink Agency",
                                    icon: "bx bx-link",
                                  }}
                                  msg={
                                    <>
                                      <p>This will Unlink Agency from your account.</p>
                                      <p>This action cannot be undone!</p>
                                    </>
                                  }
                                  onConfirm={() => dispatch(unlinkAgency(agency.id))}
                                />
                              </td>
                              {(getUserInfo()?.role === "admin" || (getUserInfo()?.role === "owner" && isParent())) &&
                              agency.stores_count > 0
                                ? (
                                <>
                                  {isWalmartStoreExists
                                    ? (
                                    <td>
                                      <PillBtn
                                        color="success"
                                        title="Payment Report"
                                        name="Payment Report"
                                        icon="bx bx-xs bx-cloud-download"
                                        onClick={() => {
                                          setAgencyMarketPlaces(
                                            props.fetchMarketplaceAccounts({ id: agency.id }, true),
                                          );
                                          setAgency(agency);
                                        }}
                                      />
                                    </td>
                                      )
                                    : null}
                                  <td>
                                    <PillBtn
                                      color="info"
                                      title="Agency Profit"
                                      name="Agency Profit"
                                      onClick={() => {
                                        setAgency(agency);
                                        props.setAgencyProfitModal(true);
                                      }}
                                    />
                                  </td>
                                </>
                                  )
                                : null}
                            </>
                          </tr>
                            ))
                          )
                        : (
                        <tr>
                          {account?.data?.is_agency && (
                            <td colSpan="6">
                              <h2 className="text-center mt-2">No Agencies Linked</h2>
                            </td>
                          )}
                        </tr>
                          )}
                    </tbody>
                  </table>
                )}
                {/* <Card>
                  <CardHeader className="p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <img src={stampsSvg} alt="stamps logo" />
                        <span className="ml-2">Add Stamps as shipment service</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <RenderIf isTrue={!fetchStampsCredsData?.account_id}>
                          <PillBtn
                            color="primary"
                            title="Add Service"
                            name="Add Stamps Service"
                            icon="bx bx-xs bx-plus"
                            className="ml-1"
                            onClick={() => openStampsLogin()}
                          />
                        </RenderIf>
                        <RenderIf isTrue={fetchStampsCredsData?.account_id}>
                          <CheckBox
                            isSwitch={true}
                            name="enabled"
                            state={fetchStampsCredsData?.enabled}
                            setState={() => setIsUSPSEnableOpen(true)}
                          />
                          <ConfirmDialogIcon
                            icon={{
                              title: "Delete Account",
                              className: "bx bx-sm text-danger bx-trash",
                            }}
                            msg={
                              <>
                                <h5>Your current details regarding stamps service will be deleted</h5>
                              </>
                            }
                            onConfirm={() => dispatch(deleteStampsCreds())}
                          />
                        </RenderIf>
                      </div>
                    </div>
                  </CardHeader>
                </Card> */}
              </>
            )}
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { AccountSettings, MarketPlaceAccount } = state;
  return { AccountSettings, MarketPlaceAccount };
};

export default connect(mapStateToProps, {
  fetchMarketplaceAccounts,
  setPaymentReportModal,
  setAgencyProfitModal,
})(AccountSettings);
