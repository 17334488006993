import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import { decodeJwtToken, getUserInfo, isJSON, setAuthTokenInBrowser, setTokenRefreshedAt } from "../../utils/common";
import { unpackRules } from "@casl/ability/extra";
import { concat, findIndex } from "lodash";
import * as types from "./actionTypes";
import * as apis from "../../api";
import * as actions from "../actions";
import * as settingActions from "./actions";
// fulfillRequests Service Status

const {
  fetchAccountSettings,
  fetchAgencies,
  verifyAgency,
  addAgencyChild,
  inviteToAgencyAPI,
  unlinkAgency,
  submitCancelFeedback,
  updateAccountSettings,
  fetchAOBotStatusApi,
  fetchAccounts,
  fetchAbilities,
  onboardUser,
  fetchTenantToken,
  checkAOServiceEligibility,
  fetchAvailableMatchesCount,
  requestMatches,
  fetchCancelledSubscriptionsApi,
  fetchFreeProxies,
  fetchActivityLogs,
  updateAgencyProfit,
  fetchNotifications,
  createNotification,
  deleteNotification,
  fetchStoreWiseStats,
  fetchTenantWiseStats,
  filterAllTenants,
  getPaymentFailedUsers,
  getCanceledUsers,
  getStatusesCount,
  getDropDownData,
  getAgencyDropdown,
  addStampsCredsAPI,
  deleteStampsCredsAPI,
  enableStampsCredsAPI,
  fetchStampsCredsAPI,
  matchesRequestService,
} = apis;

// Login Redux States
const {
  UPDATE_ACCOUNT_SETTINGS,
  FETCH_ACCOUNT_SETTINGS,
  VERIFY_AGENCY_INVITE,
  FETCH_ABILITIES,
  FETCH_PROXIES,
  ADD_AGENCY_CHILD_ACCOUNT,
  INVITE_TO_AGENCY,
  ADD_STAMP_CREDENTIALS,
  UNLINK_AGENCY,
  UPDATE_ONBORDING_FLAG,
  FETCH_TOKEN,
  FETCH_AO_BOT_STATUS,
  SUBMIT_CANCEL_FEEDBACK,
  CHECK_FOR_AO_SERVICE_UPDATE,
  FETCH_AVAILABLE_MATCH_COUNTS,
  SUBMIT_MATCHES_REQUEST,
  FETCH_CANCELLED_SUBSCRIPTIONS,
  FETCH_ACTIVITY_LOGS,
  UPDATE_AGENCY_PROFIT,
  FETCH_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  FETCH_STORE_WISE_STATS,
  FETCH_TENANT_WISE_STATS,
  FILTER_ALL_TENANTS,
  GET_PAYMENT_FAILED_USERS,
  GET_CANCELED_USERS,
  GET_STATUSES_COUNT,
  GET_DROPDOWN_DATA,
  GET_AGENCY_DROPDOWN_DATA,
  FETCH_MATCHES_REQUESTS_STATUS,
  FETCH_STAMPS_CREDS,
  DELETE_STAMPS_CREDS,
  ENABLE_STAMPS_CREDS,
} = types;

const {
  fetchProxiesSuccess,
  fetchTokenSuccess,
  fetchAgenciesSuccess,
  setAddAgencyModal,
  verifyAgencySuccess,
  setVerifyError,
  setAddClientModal,
  addAgencyChildAccountSuccess,
  inviteToAgencySuccess,
  unlinkAgencySuccess,
  fetchAccountSettingsSuccess,
  fetchAllAccountSettingsSuccess,
  fetchAbilitiesSuccess,
  updateAccountSettingsSuccess,
  fetchAOBotStatusSuccess,
  submitCancelFeedbackSuccess,
  checkForAOStatusUpdateSuccess,
  fetchAvailableMatchCountsSuccess,
  submitMatchesRequestSuccess,
  fetchCancelledSubscriptionsSuccess,
  fetchActivityLogsSuccess,
  updateAgencyProfitSuccess,
  fetchNotificationsSuccess,
  createNotificationSuccess,
  fetchStoreWiseStatsSuccess,
  fetchTenantWiseStatsSuccess,
  fetchAccountsSuccess,
  filterAllTenantsSuccess,
  getPaymentFailedUsersSuccess,
  getCanceledUserSuccess,
  getStatusesCountSuccess,
  getDropDownDataSuccess,
  fetchFulfillMatchesStatusSuccess,
  addStampsCredsSuccess,
  apiError,
  fetchStampsCredsSuccess,
  getAgencyDropDownSuccess,
} = actions;

const Settings = (state) => state.Settings;

function * fetchAccountSettingsSaga ({ payload: { isAllTenants } }) {
  try {
    const allAgencies = yield call(fetchAgencies);
    const findMainAcc = (arr) => arr?.find((x) => x.id === getUserInfo()?.account_id);
    const data = findMainAcc(allAgencies?.data);

    yield put(fetchAllAccountSettingsSuccess(allAgencies.data));
    yield put(fetchAccountSettingsSuccess({ data }));
    yield put(fetchAccountsSuccess({ accounts: allAgencies.data, roles: {} }));
    yield put(fetchAgenciesSuccess(allAgencies.data));

    if (isAllTenants) {
      const allAccounts = yield call(fetchAccounts);
      yield put(fetchAllAccountSettingsSuccess(allAccounts.accounts));
      yield put(fetchAccountsSuccess(allAccounts));
      if (allAccounts?.accounts) {
        yield put(fetchAccountSettingsSuccess({ data: findMainAcc(allAccounts?.accounts) }));
      }
    } else {
      yield put(fetchAllAccountSettingsSuccess(allAgencies.data));
      const res = yield call(fetchAccountSettings, { id: getUserInfo()?.account_id, fetch_group: true });
      yield put(fetchAccountSettingsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * updateAccountSettingsSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updateAccountSettings, { data, id });
    if (res?.data) {
      const settings = yield select(Settings);
      const finalId = id || +getUserInfo()?.account_id;
      if (+getUserInfo()?.account_id === finalId) settings.account.data = { ...settings.account.data, ...res.data };
      if (finalId && settings.allTenants) {
        const index = settings.allTenants.findIndex((x) => x.id === id);
        settings.allTenants[index] = res.data;
      }
      yield put(updateAccountSettingsSuccess());
    }
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchAOBotStatus () {
  try {
    const res = yield call(fetchAOBotStatusApi);
    yield put(fetchAOBotStatusSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchfulfillMatchesServiceStatus () {
  try {
    const res = yield call(matchesRequestService);
    yield put(fetchFulfillMatchesStatusSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchAbilitySaga () {
  try {
    const res = yield call(fetchAbilities);
    if (res.rules) yield put(fetchAbilitiesSuccess(unpackRules(res.rules)));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchProxiesSaga () {
  try {
    const res = yield call(fetchFreeProxies);
    yield put(fetchProxiesSuccess(res.data));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchCancelledSubscriptionsSaga () {
  try {
    const res = yield call(fetchCancelledSubscriptionsApi);
    yield put(fetchCancelledSubscriptionsSuccess(res.subscriptions));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * verifyAgencySaga ({ payload: { token } }) {
  try {
    const response = yield call(verifyAgency, { token });
    yield put(verifyAgencySuccess(response));
  } catch (error) {
    yield put(setVerifyError(error.message));
  }
}

function * addAgencyChildAccountSaga ({ payload: { data } }) {
  try {
    const res = yield call(addAgencyChild, { data });
    if (res.success) {
      yield put(setAddAgencyModal(false));
      yield put(setAddClientModal(false));
    }
    yield put(addAgencyChildAccountSuccess());
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * submitCancelFeedbackSaga ({ payload }) {
  try {
    yield call(submitCancelFeedback, payload);
    yield put(submitCancelFeedbackSuccess());
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * inviteToAgencySaga ({ payload: { data } }) {
  try {
    const res = yield call(inviteToAgencyAPI, { data });
    if (res.success) yield put(setAddAgencyModal(false));
    yield put(inviteToAgencySuccess());
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * addStampsCredsSaga ({ payload: { data } }) {
  try {
    const res = yield call(addStampsCredsAPI, { data });
    yield put(addStampsCredsSuccess(res));
    if (res?.data) yield put(fetchStampsCredsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchStampsCredsSaga () {
  try {
    const res = yield call(fetchStampsCredsAPI);
    yield put(fetchStampsCredsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * deleteStampsCredsSaga () {
  try {
    const res = yield call(deleteStampsCredsAPI);
    if (res?.success) yield put(fetchStampsCredsSuccess({}));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * enableStampsCredsSaga ({ payload }) {
  try {
    const res = yield call(enableStampsCredsAPI, payload);
    if (res?.success) yield put(fetchStampsCredsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchActivityLogsSaga ({ payload }) {
  try {
    const res = yield call(fetchActivityLogs, payload);
    yield put(fetchActivityLogsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchStoreWiseSaga ({ payload }) {
  try {
    const res = yield call(fetchStoreWiseStats, payload);
    yield put(fetchStoreWiseStatsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchTenantWiseSaga ({ payload }) {
  try {
    const res = yield call(fetchTenantWiseStats, payload);
    yield put(fetchTenantWiseStatsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * unlinkAgencySaga ({ payload: { id } }) {
  try {
    const res = yield call(unlinkAgency, { id });
    if (res.success) {
      const settings = yield select(Settings);
      settings.agencies.data = res.data;
      yield put(fetchAllAccountSettingsSuccess(res.data));
    }
    yield put(unlinkAgencySuccess());
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * fetchTokenSaga ({ payload: { email, qP } }) {
  try {
    const res = yield call(fetchTenantToken, { email, qP });
    if (res.token) {
      setAuthTokenInBrowser(res.token);
      yield put(fetchTokenSuccess());
      const token = decodeJwtToken(res.token);
      window.location.replace(token.agency_id ? document.location.pathname : "/agency");
    } else {
      yield put(fetchTokenSuccess());
    }
  } catch (error) {
    if (error.message.includes("Unauthorized")) {
      const settings = yield select(Settings);
      settings.allTenants = settings.allTenants.filter((x) => x.id !== error.id);
    }
    yield put(apiError(error.message || error));
  }
}

function * refreshAuthTokenSaga () {
  try {
    const { newToken } = yield call(apis.refreshAuthToken);

    if (newToken) setAuthTokenInBrowser(newToken);
    else setTokenRefreshedAt();
    yield put(actions.refreshAuthTokenSuccess());
  } catch (error) {
  }
}

function * updateOnboardingFlagSaga ({ payload: { data } }) {
  try {
    const res = yield call(onboardUser, data);
    if (res?.data) {
      const settings = yield select(Settings);
      settings.account.data = {
        ...settings.account.data,
        ...res.data,
      };
      yield put(updateAccountSettingsSuccess());
      if (res?.token) {
        setAuthTokenInBrowser(res.token);
        document.location.reload();
      }
    }
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * checkAOServiceEligibilitySaga ({ payload }) {
  try {
    const res = yield call(checkAOServiceEligibility, payload);
    if (res.success) {
      const settings = yield select(Settings);
      settings.account.data = {
        ...settings.account.data,
        ...res.data,
        aoOffReasons: res.aoOffReasons || [],
      };
      yield put(checkForAOStatusUpdateSuccess());
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * filterAllTenantsSaga ({ payload: { params } }) {
  try {
    const res = yield call(filterAllTenants, params);
    yield put(filterAllTenantsSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getPaymentFailedUsersSaga () {
  try {
    const res = yield call(getPaymentFailedUsers);
    yield put(getPaymentFailedUsersSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getCanceledUsersSaga () {
  try {
    const res = yield call(getCanceledUsers);
    yield put(getCanceledUserSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getStatusesCountSaga () {
  try {
    const res = yield call(getStatusesCount);
    yield put(getStatusesCountSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getDropDownDataSaga ({ payload: { params } }) {
  try {
    const res = yield call(getDropDownData, params);

    if (res?.data) {
      const { dropdownData } = yield select(Settings);
      res.data = concat(dropdownData?.data || [], res?.data);
    }
    yield put(getDropDownDataSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * getAgencyDropDownDataSaga () {
  try {
    const res = yield call(getAgencyDropdown);
    yield put(getAgencyDropDownSuccess(res));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

const getDetailsMessage = (error) => isJSON(error) ? isJSON(error)?.details?.[0]?.message : error.message;

function * fetchAvailableMatchesCountSaga ({ payload: { params } }) {
  try {
    const res = yield call(fetchAvailableMatchesCount, params);
    yield put(fetchAvailableMatchCountsSuccess(res));
  } catch (error) {
    yield put(settingActions.apiError(getDetailsMessage(error)));
  }
}

function * submitMatchesRequestSaga ({ payload: { data } }) {
  try {
    const res = yield call(requestMatches, data);
    if (res.success) yield put(setAddAgencyModal(false));
    yield put(submitMatchesRequestSuccess());
  } catch (error) {
    yield put(settingActions.apiError(getDetailsMessage(error) || error));
  }
}

function * fetchNotificationsSaga ({ payload: { id, offset } }) {
  try {
    const res = yield call(fetchNotifications, { id, offset });
    if (res?.notifications) {
      const { notifications } = yield select(Settings);
      res.notifications = concat(notifications, res.notifications);
      yield put(fetchNotificationsSuccess(res));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateAgencyProfitSaga ({ payload: { data, id } }) {
  try {
    const res = yield call(updateAgencyProfit, { data, id });
    if (res?.data) {
      const settings = yield select(Settings);
      const index = findIndex(settings.agencies?.data, (x) => x.id === id);
      settings.agencies.data[index].agency_profit = data.agency_profit;
      yield put(updateAgencyProfitSuccess(data));
    }
  } catch (error) {
    yield put(apiError(error.message || error));
  }
}

function * createNotificationSaga ({ payload }) {
  try {
    yield call(createNotification, payload);
    yield put(createNotificationSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * deleteNotificationSaga ({ payload: id }) {
  try {
    yield call(deleteNotification, id);
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function * updateAgencyNameSaga ({ payload: { id, body, type } }) {
  try {
    const res = yield call(apis.updateAgencyNameAPI, { id, body });
    if (res?.success) yield put(actions.updateAgencyNameSuccess({ res, type }));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export function * watchFetchNotificationsSaga () {
  yield takeEvery(FETCH_NOTIFICATIONS, fetchNotificationsSaga);
}

export function * watchFilterAllTenantsSaga () {
  yield takeEvery(FILTER_ALL_TENANTS, filterAllTenantsSaga);
}

export function * watchCreateNotificationsSaga () {
  yield takeEvery(CREATE_NOTIFICATION, createNotificationSaga);
}

export function * watchDeleteNotificationsSaga () {
  yield takeEvery(DELETE_NOTIFICATION, deleteNotificationSaga);
}

export function * watchaccSettingFetch () {
  yield takeEvery(FETCH_ACCOUNT_SETTINGS, fetchAccountSettingsSaga);
}

export function * watchTokenFetch () {
  yield takeEvery(FETCH_TOKEN, fetchTokenSaga);
}

export function * watchRefreshAuthToken () {
  yield takeEvery(types.REFRESH_AUTH_TOKEN, refreshAuthTokenSaga);
}

export function * watchFetchActivityLogs () {
  yield takeEvery(FETCH_ACTIVITY_LOGS, fetchActivityLogsSaga);
}

export function * watchFetchStoreWiseStats () {
  yield takeEvery(FETCH_STORE_WISE_STATS, fetchStoreWiseSaga);
}

export function * watchFetchTenantWiseStats () {
  yield takeEvery(FETCH_TENANT_WISE_STATS, fetchTenantWiseSaga);
}

export function * watchaccSettingUpdate () {
  yield takeEvery(UPDATE_ACCOUNT_SETTINGS, updateAccountSettingsSaga);
}

export function * watchFetchCancelledSubscriptions () {
  yield takeEvery(FETCH_CANCELLED_SUBSCRIPTIONS, fetchCancelledSubscriptionsSaga);
}

export function * watchFetchAOBotStatus () {
  yield takeEvery(FETCH_AO_BOT_STATUS, fetchAOBotStatus);
}

export function * watchFetchfulfillMatchesServiceStatus () {
  yield takeEvery(FETCH_MATCHES_REQUESTS_STATUS, fetchfulfillMatchesServiceStatus);
}

export function * watchFetchAbilities () {
  yield takeEvery(FETCH_ABILITIES, fetchAbilitySaga);
}

export function * watchFetchProxies () {
  yield takeEvery(FETCH_PROXIES, fetchProxiesSaga);
}

export function * watchVerifyAgencySaga () {
  yield takeEvery(VERIFY_AGENCY_INVITE, verifyAgencySaga);
}

export function * watchAddAgencyChildAccount () {
  yield takeEvery(ADD_AGENCY_CHILD_ACCOUNT, addAgencyChildAccountSaga);
}

export function * watchInviteToAgency () {
  yield takeEvery(INVITE_TO_AGENCY, inviteToAgencySaga);
}

export function * watchAddStampsCreds () {
  yield takeEvery(ADD_STAMP_CREDENTIALS, addStampsCredsSaga);
}

export function * watchFetchStampsCreds () {
  yield takeEvery(FETCH_STAMPS_CREDS, fetchStampsCredsSaga);
}

export function * watchDeleteStampsCredsSaga () {
  yield takeEvery(DELETE_STAMPS_CREDS, deleteStampsCredsSaga);
}

export function * watchEnableStampsCredsSaga () {
  yield takeEvery(ENABLE_STAMPS_CREDS, enableStampsCredsSaga);
}

export function * watchUnlinkAgency () {
  yield takeEvery(UNLINK_AGENCY, unlinkAgencySaga);
}

export function * watchOnboardingFlagUpdateFetch () {
  yield takeEvery(UPDATE_ONBORDING_FLAG, updateOnboardingFlagSaga);
}

export function * watchCheckAOServiceEligibility () {
  yield takeEvery(CHECK_FOR_AO_SERVICE_UPDATE, checkAOServiceEligibilitySaga);
}

export function * watchCheckSubmitCancelFeedback () {
  yield takeEvery(SUBMIT_CANCEL_FEEDBACK, submitCancelFeedbackSaga);
}

export function * watchFetchAvailableMatchesCountsSaga () {
  yield takeEvery(FETCH_AVAILABLE_MATCH_COUNTS, fetchAvailableMatchesCountSaga);
}

export function * watchSubmitMatchesRequestSaga () {
  yield takeEvery(SUBMIT_MATCHES_REQUEST, submitMatchesRequestSaga);
}

export function * watchUpdateAgencyProfit () {
  yield takeEvery(UPDATE_AGENCY_PROFIT, updateAgencyProfitSaga);
}

export function * watchPaymentFailedUsersSaga () {
  yield takeEvery(GET_PAYMENT_FAILED_USERS, getPaymentFailedUsersSaga);
}

export function * watchCanceledUsersSaga () {
  yield takeEvery(GET_CANCELED_USERS, getCanceledUsersSaga);
}

export function * watchStatusesCountSaga () {
  yield takeEvery(GET_STATUSES_COUNT, getStatusesCountSaga);
}

export function * watchDropDownDataSaga () {
  yield takeEvery(GET_DROPDOWN_DATA, getDropDownDataSaga);
}

export function * watchAgencyDropDownDataSaga () {
  yield takeEvery(GET_AGENCY_DROPDOWN_DATA, getAgencyDropDownDataSaga);
}

export function * watchUpdateAgencyNameSaga () {
  yield takeEvery(types.UPDATE_AGENCY_NAME, updateAgencyNameSaga);
}

function * AccountSettingsSaga () {
  yield all([
    fork(watchCreateNotificationsSaga),
    fork(watchDeleteNotificationsSaga),
    fork(watchFetchNotificationsSaga),
    fork(watchaccSettingFetch),
    fork(watchaccSettingUpdate),
    fork(watchTokenFetch),
    fork(watchRefreshAuthToken),
    fork(watchOnboardingFlagUpdateFetch),
    fork(watchFetchAOBotStatus),
    fork(watchAddAgencyChildAccount),
    fork(watchInviteToAgency),
    fork(watchUnlinkAgency),
    fork(watchFetchAbilities),
    fork(watchVerifyAgencySaga),
    fork(watchCheckAOServiceEligibility),
    fork(watchFetchAvailableMatchesCountsSaga),
    fork(watchSubmitMatchesRequestSaga),
    fork(watchCheckSubmitCancelFeedback),
    fork(watchFetchCancelledSubscriptions),
    fork(watchFetchProxies),
    fork(watchFetchActivityLogs),
    fork(watchFetchStoreWiseStats),
    fork(watchFetchTenantWiseStats),
    fork(watchUpdateAgencyProfit),
    fork(watchFilterAllTenantsSaga),
    fork(watchPaymentFailedUsersSaga),
    fork(watchCanceledUsersSaga),
    fork(watchStatusesCountSaga),
    fork(watchDropDownDataSaga),
    fork(watchAgencyDropDownDataSaga),
    fork(watchFetchfulfillMatchesServiceStatus),
    fork(watchAddStampsCreds),
    fork(watchFetchStampsCreds),
    fork(watchDeleteStampsCredsSaga),
    fork(watchEnableStampsCredsSaga),
    fork(watchUpdateAgencyNameSaga),
  ]);
}
export default AccountSettingsSaga;
