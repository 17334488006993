import { useEffect } from "react";

const ChatBot = () => {
  useEffect(() => {
    // Create a script element for FrontChat
    const script = document.createElement("script");
    script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
    script.async = true;
    
    // Append script to the document body
    document.body.appendChild(script);

    // Initialize FrontChat when script loads
    script.onload = () => {
      if (window.FrontChat) {
        window.FrontChat("init", {
          chatId: "7630f3ea1f9ac18430be7f392cf95f42",
          useDefaultLauncher: true,
        });
      }
    };

    // Cleanup on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // No UI needed, just the script
};

export default ChatBot;
